import Test_img_3 from "./test_3.png";
//
import Hero_img from "./liste_projets.png";
import Logo_company from "./ico2.png";
import Bg_img from "./bg.png";
import Bg_img_IA from "./bg_ia.png";
import Feature_hero_img_1 from "./feature_hero_1.png";
import Feature_hero_img_2 from "./feature_hero_2.png";
import Feature_hero_IA_img_1 from "./feature_hero_ia_1.png";
import Feature_hero_IA_img_2 from "./feature_hero_ia_2.png";
import Feature_hero_img_sim from "./feature_hero_sim.png";
import Feature_image_other from "./feature_other.png";
import OgGraph_img from "./ogGraph.png";

export {
  Logo_company,
  Hero_img,
  Test_img_3,
  Bg_img,
  Bg_img_IA,
  Feature_hero_img_1,
  Feature_hero_img_2,
  Feature_hero_IA_img_1,
  Feature_hero_IA_img_2,
  Feature_hero_img_sim,
  Feature_image_other,
  OgGraph_img,
};
