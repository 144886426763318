import Link from "next/link";
import React from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import Image from "next/image";
import { Logo_company } from "@/public";

type Props = {};

const footer_2 = (props: Props) => {
  return (
    <div className="bg-[#181623]">
      <footer className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <section className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <Link
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <Image
                alt="Logo Flash Report"
                className="w-16 object-contain object-center"
                src={Logo_company}
              />
              <span className="ml-2 text-xl font-bold tracking-wide text-gray-200 uppercase">
                Flash Report
              </span>
            </Link>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-gray-200">
                Découvrez Flash Report, votre allié en gestion de projet. Notre
                plateforme, dotée d'IA et d'algorithmes avancés, simplifie et
                accélère la gestion de projets pour toutes méthodologies -
                Agile, Waterfall, Kanban.
              </p>
              <p className="mt-4 text-sm text-gray-200">
                Conçu pour les chefs de projet, directeurs de projet, et DSI,
                Flash Report facilite des décisions rapides et éclairées.
                Transformez votre gestion de projet avec Flash Report et
                améliorez efficacité et performance. Propulsez vos projets vers
                le succès !
              </p>
            </div>
          </div>
          <section
            aria-label="Contact information"
            className="space-y-2 text-sm"
          >
            <h2 className="text-base font-bold tracking-wide text-gray-200">
              Liens rapides
            </h2>
            <div className="flex">
              <Link
                href="/"
                aria-label="Accueil"
                title="Accueil"
                className="transition-colors duration-300 text-gray-200 hover:text-gray-400"
              >
                Accueil
              </Link>
            </div>
            <div className="flex">
              <Link
                href="/pricing"
                aria-label="Nos offres"
                title="Nos offres"
                className="transition-colors duration-300 text-gray-200 hover:text-gray-400"
              >
                Nos offres
              </Link>
            </div>
            {/* <div className="flex">
              <Link
                href="#"
                target="_blank"
                aria-label="Agenda"
                title="Agenda"
                className="transition-colors duration-300 text-gray-200 hover:text-gray-400"
              >
                Prendre un rdv
              </Link>
            </div> */}
          </section>
          <section aria-label="Social media links">
            <h2 className="text-base font-bold tracking-wide text-gray-200">
              Social
            </h2>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://x.com/cyde_sas?s=21"
                className="text-gray-200 transition-colors duration-300 hover:text-gray-200"
                aria-label="Twitter"
                target="_blank"
              >
                <FaSquareXTwitter className="text-xl" />
              </a>
              <a
                href="https://www.linkedin.com/company/cyde-sas/"
                className="text-gray-200 transition-colors duration-300 hover:text-gray-200"
                aria-label="Linkedin"
                target="_blank"
              >
                <FaLinkedin className="text-xl" />
              </a>
              <Link
                href="https://www.facebook.com/societe.cyde"
                className="text-gray-200 transition-colors duration-300 hover:text-gray-200"
                aria-label="Facebook"
                target="_blank"
              >
                <FaFacebookSquare className="text-xl" />
              </Link>
            </div>
            <p className="mt-4 text-sm text-gray-200">
              Suivez nous sur les réseaux sociaux
            </p>
          </section>
        </section>
        <section className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <p className="text-sm text-gray-200">
            © 2024 - CYDE - Tous droits réservés
          </p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            {/* <li>
              <Link
                href="#"
                className="text-sm text-gray-200 transition-colors duration-300 hover:text-gray-200"
              >
                F.A.Q
              </Link>
            </li> */}
            <li>
              <Link
                href="/legal"
                className="text-sm text-gray-200 transition-colors duration-300 hover:text-gray-200"
              >
                Mention légale
              </Link>
            </li>
            <li>
              <a
                className="text-sm text-gray-200 transition-colors duration-300 hover:text-gray-200"
                href="https://cyde.fr/"
                aria-label="Découvrez l'entreprise CYDE"
                title="Découvrez l'entreprise CYDE"
                target="_blank"
              >
                Cyde
              </a>
            </li>
          </ul>
        </section>
      </footer>
    </div>
  );
};

export default footer_2;
