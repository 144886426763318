"use client";
import React, { useState } from "react";
import Image from "next/image";
import { Gauge_simulator } from "@/components/common";
import { Feature_hero_img_sim } from "@/public";

type Props = {};

const simulator = (props: Props) => {
  const [dataFromChild, setDataFromChild] = useState(Object);

  const handleDataFromChild = (data: object) => {
    setDataFromChild(data);
  };

  return (
    <div className="bg-[#EDECFB] w-full h-full pb-8 relative">
      <section className="h-full flex justify-center items-center flex-col">
        <div className="mx-auto flex max-w-[58rem] flex-col items-center text-center pt-12 md:pt-24">
          <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl mb-4 md:mb-8 text-[#272735]">
            Simulez vos gains et économies
          </h2>
          <p className="max-w-[85%] text-[#554e7b] leading-normal sm:text-lg sm:leading-7 mb-12 md:mb-16">
            Notre simulateur estime vos gains de productivité et leur traduction
            financière. Interact with the slider to see how it works. You will
            see how the price changes as you move the slider.
          </p>
        </div>
        <div className="w-full h-full flex justify-center items-center flex-col lg:flex-row px-8 lg:px-32">
          <div className="w-full lg:w-2/4 h-full">
            <Gauge_simulator
              pricing={false}
              dataFromChild={handleDataFromChild}
            />
          </div>
          <div className="hidden lg:flex w-2/4 rounded-xl">
            <Image
              alt="simulator image"
              className="object-center object-contain w-full h-full rounded-xl shadow-[rgba(0,_0,_0,_0.4)_0px_30px_40px]"
              src={Feature_hero_img_sim}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default simulator;
