"use client";
import React, { useEffect, useState } from "react";
import { Gauge_simulator } from "@/components/common";

type Props = {};

const simulator = (props: Props) => {
  const [dataFromChild, setDataFromChild] = useState(Object);

  const handleDataFromChild = (data: object) => {
    setDataFromChild(data);
  };

  return (
    <div className="bg-[#EDECFB] w-full h-full flex flex-col pb-20">
      <div className="mx-auto max-w-[58rem] text-center pt-12 md:pt-24 lg:pt-32">
        <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl text-[#272735]">
          Optimisez votre gestion de projet avec Flash Report
        </h2>
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-center items-center pt-8 md:pt-16">
        <section className="h-full flex flex-col justify-center items-center flex-col w-full lg:w-2/4">
          <div className="w-full h-full flex justify-center items-center flex-col lg:flex-row px-8 lg:px-12">
            <div className="h-full">
              <Gauge_simulator
                pricing={true}
                dataFromChild={handleDataFromChild}
              />
            </div>
          </div>
        </section>
        <section className="flex justify-center items-center flex-col w-full h-full lg:w-2/4 pr-8 md:pr-12 lg:pr-24 gap-6">
          {/* section 1 */}
          <div className="w-full flex flex-col gap-1">
            <div className="flex flex-row justify-between items-center">
              <p>Chefs de Projet</p>
              <p>{dataFromChild.cpEquivalent}</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Directeurs de Projet</p>
              <p>{dataFromChild.dpEquivalent}</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Cadres Dirigeants</p>
              <p>{dataFromChild.dEquivalent}</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Total :</p>
              <p> {dataFromChild.totalEquivalent}</p>
            </div>
          </div>
          {/* section 2 */}
          <div className="w-full flex flex-col gap-1">
            <div className="flex flex-row justify-between items-center">
              <p>Coût Annuel Estimé de Pilotage</p>
              <p>{dataFromChild.annualManagementCost} €</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>TJM Moyen</p>
              <p>{dataFromChild.averageTJM?.toFixed(2)} €</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Heures mensuelles gagnées</p>
              <p>{dataFromChild.totalHoursSaved} h</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Traduction en gain financiers</p>
              <p>{dataFromChild.totalMonthlySavings} €</p>
            </div>
          </div>
          {/* section 3 */}
          <div className="w-full flex flex-col gap-1">
            <div className="flex flex-row justify-between items-center">
              <p>Économies Annuelles</p>
              <p>{dataFromChild.annualSavings} €</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Prix du produit</p>
              <p>{dataFromChild.annualCostFR} €</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Économies Nettes Réalisées</p>
              <p>{dataFromChild.netAnnualSavings} €</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Marge sur le pilotage</p>
              <p>{dataFromChild?.marginGainsPercentage?.toFixed(2)}%</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default simulator;
