"use client";
import Link from "next/link";
import React, { useEffect, useState } from "react";

type Props = {
  pricing: boolean;
  dataFromChild?: any;
};

const gauge_simulator = (props: Props) => {
  const [numberOfUsers, setNumberOfUsers] = useState(10);
  const [isChosen, setIsChosen] = useState(2);
  const [chosenOffer, setChosenOffer] = useState("enterprise");

  const BASIC_PRICE = 19;
  const PREMIUM_PRICE = 49;
  const ENTERPRISE_PRICE = 60;
  const MONTHS_IN_YEAR = 10;

  const TJM_CP = 400;
  const TJM_DP = 500;
  const TJM_D = 700;

  const calculateSavings = () => {
    const discount = 1;
    const pricePerUser =
      chosenOffer === "basic"
        ? BASIC_PRICE * discount
        : (chosenOffer === "premium" ? PREMIUM_PRICE : ENTERPRISE_PRICE) *
          discount;

    const cpCount = Math.round(
      numberOfUsers < 4
        ? numberOfUsers
        : numberOfUsers >= 15
        ? numberOfUsers - numberOfUsers / 4 - numberOfUsers / 15
        : numberOfUsers - numberOfUsers / 4
    );
    const dpCount = Math.round(numberOfUsers < 4 ? 0 : numberOfUsers / 4);
    const dCount = Math.round(numberOfUsers < 15 ? 0 : numberOfUsers / 15);

    const timeSavedCP =
      chosenOffer === "basic"
        ? 2.5 * cpCount
        : (chosenOffer === "premium" ? 3.25 : 11.25) * cpCount;
    const timeSavedDP =
      chosenOffer === "basic"
        ? 2.5 * dpCount
        : (chosenOffer === "premium" ? 3 : 7) * dpCount;
    const timeSavedD =
      chosenOffer === "basic"
        ? dCount
        : (chosenOffer === "premium" ? 1.25 : 2.25) * dCount;
    const totalHoursSaved = timeSavedCP + timeSavedDP + timeSavedD;

    const monthlySavingsCP = Math.round((timeSavedCP * TJM_CP) / 7);
    const monthlySavingsDP = Math.round((timeSavedDP * TJM_DP) / 7);
    const monthlySavingsD = Math.round((timeSavedD * TJM_D) / 7);
    const totalMoneySaved =
      monthlySavingsCP + monthlySavingsDP + monthlySavingsD;

    const monthlySavings = totalMoneySaved;
    const annualSavings = totalMoneySaved * MONTHS_IN_YEAR;

    const monthlyCostFR = pricePerUser * numberOfUsers;
    const annualCostFR = monthlyCostFR * 12;

    const netAnnualSavings = annualSavings - annualCostFR;
    const financialGainsPercentage = (annualCostFR / netAnnualSavings) * 100;

    const annualManagementCost =
      cpCount * TJM_CP * 200 +
      dpCount * TJM_DP * 200 +
      (dCount * TJM_D * 200) / 4;
    const annualManagementDays =
      cpCount * 200 + dpCount * 200 + (dCount * 200) / 4;

    let marginGainsPercentage = (netAnnualSavings / annualManagementCost) * 100;
    marginGainsPercentage = numberOfUsers !== 0 ? marginGainsPercentage : 0;

    const averageTJM = annualManagementCost / annualManagementDays;

    return {
      cpEquivalent: cpCount,
      dpEquivalent: dpCount,
      dEquivalent: dCount,
      totalEquivalent: cpCount + dpCount + dCount,
      timeSavedCP,
      timeSavedDP,
      timeSavedD,
      totalHoursSaved,
      totalMoneySaved,
      monthlySavingsCP,
      monthlySavingsDP,
      monthlySavingsD,
      totalMonthlySavings: monthlySavings,
      totalAnnualSavings: annualSavings,
      monthlyCostFR,
      annualCostFR,
      annualSavings,
      netAnnualSavings,
      financialGainsPercentage,
      annualManagementCost,
      marginGainsPercentage,
      averageTJM,
    };
  };

  const [savings, setSavings] = useState(calculateSavings());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberOfUsers(parseInt(e.target.value, 10) || 0);
    props.dataFromChild(calculateSavings());
  };

  const handleClickButton = (offer: string, index: number) => {
    setChosenOffer(offer);
    setIsChosen(index);
    props.dataFromChild(calculateSavings());
  };

  useEffect(() => {
    calculateSavings();
    props.dataFromChild(calculateSavings());
  }, []);

  // useEffect(
  //   () => console.log("The value after update", dataFromChild),
  //   [dataFromChild]
  // );

  return (
    <div className="mt-6 border-t pt-4 px-0 sm:px-12 lg:px-8 py-0 sm:py-12 lg:py-8 lg:max-w-sm mx-auto w-full sm:bg-[#E3DFED] rounded-xl">
      {props.pricing ? (
        <div className="flex flex-auto justify-evenly rounded-md w-full mb-4">
          <button
            className={`${
              isChosen === 0
                ? "bg-[#4F46E5] text-white rounded-l-md"
                : "border border-[#4F46E5] rounded-l-md"
            } px-2 py-1 w-full`}
            onClick={() => handleClickButton("basic", 0)}
          >
            Automatisation
          </button>

          <button
            className={`${
              isChosen === 1
                ? "bg-[#4F46E5] text-white"
                : "border border-[#4F46E5]"
            } px-2 py-1 w-full`}
            onClick={() => handleClickButton("premium", 1)}
          >
            Algorithmie
          </button>

          <button
            className={`${
              isChosen === 2
                ? "bg-[#4F46E5] text-white rounded-r-md"
                : "border border-[#4F46E5] rounded-r-md"
            } px-2 py-1 w-full`}
            onClick={() => handleClickButton("enterprise", 2)}
          >
            IA
          </button>
        </div>
      ) : (
        <div className="w-full">
          <h2 className="text-xl font-semibold mb-4">
            Simulation de l'offre Premium
          </h2>
        </div>
      )}
      <div x-data="{ pageviews: 1000, price: 0 }" className="w-full">
        <div className="flex flex-col items-start gap-x-2 w-full">
          {" "}
          <label htmlFor="slide" className="block text-[#554e7b]">
            Bougez le curseur
          </label>
          <input
            type="range"
            id="pageviews"
            x-model="pageviews"
            min="0"
            max="100"
            step="1"
            value={numberOfUsers}
            onChange={handleChange}
            className="w-full mt-2 border border-gray-300 appearance-none rounded-full h-8 px-2 outline-none overflow-hidden"
          />
        </div>
        <div className="mt-4 flex flex-col items-start gap-x-2 w-full">
          {" "}
          <label htmlFor="pageviews" className="block text-[#554e7b]">
            Nombre d'utilisateur
          </label>{" "}
          <input
            type="number"
            id="inputPageviews"
            x-model="pageviews"
            min="0"
            max="1000"
            step="1"
            value={numberOfUsers}
            onChange={handleChange}
            className="border border-gray-300 rounded-lg px-2 py-1 w-full h-10 text-gray-700 outline-none focus:ring focus:ring-[#140f6b]"
          />{" "}
        </div>
        <div className="rounded-2xl p-8 mt-4 w-full border-2 bg-white lg:flex lg:flex-col lg:justify-center">
          <p className="text-2xl font-medium text-black">
            <span className="font-bold">
              {calculateSavings().netAnnualSavings}€
            </span>{" "}
            <br />
            d'économies Nettes Réalisées
          </p>
          <div className="flex items-center gap-x-2 mt-4 text-gray-500">
            Marge sur le pilotage :{" "}
            {calculateSavings().marginGainsPercentage.toFixed(2)}%
          </div>
          {!props.pricing ? (
            <div className="mt-4">
              {" "}
              <button
                type="submit"
                className="justify-center flex items-center gap-x-2 py-2 px-6 text-base text-white bg-[#131315] hover:bg-[#140f6b] rounded-lg transition duration-200"
              >
                <Link href="/pricing" aria-label="Pricing">
                  Voir les offres
                </Link>
              </button>{" "}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default gauge_simulator;
