import React from "react";

type Props = {};

const title_feature_IA = (props: Props) => {
  return (
    <div className="w-full flex justify-center items-center pt-24 pb-4 bg-[black]">
      <div className="mx-auto max-w-[58rem] text-center">
        <h2 className="font-bold text-3xl leading-5 sm:text-3xl md:text-6xl text-white">
          Optimisez vos projets avec l'Intelligence Artificielle
        </h2>
      </div>
    </div>
  );
};

export default title_feature_IA;
